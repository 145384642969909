import { useEffect, useState } from 'react';

import {
  StopoverGroupbyDockingStatisticsType,
  StopoverTypeDescription,
  VesselTypeDescription,
  DockingTypeDescription,
  StopoverGroupByOperatorType,
  StopoversGroupByOperatorType,
} from '../../../types';
import {
  Collapse,
  Descriptions,
  Drawer,
  GenericDrawerHeader,
  InnerCollapse,
  StaymentLog,
} from '../../../components';
import { useChangePagination, useCollapsePanels } from '../../../hooks';
import {
  useGetStopoverGroupbyOperatorQuery,
  useLazyGetStopoverQuery,
} from '../../../services/stopoverApi';
import { Container } from '../faresData/styles';
import { OperationsTable } from '../../reports/operationsSection/operationsTable';
import { OperationReportDrawer } from '../../reports/operationsSection/operationReportDrawer/OperationReportDrawer';

type DockingReportDrawerProps = {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  selectedStopover: any;
  // setSelectedDocking: Dispatch<
  //   SetStateAction<StopoverGroupbyDockingStatisticsType>
  // >;
};

const { Panel } = Collapse;
const { Panel: InnerPanel } = InnerCollapse;
const { Item } = Descriptions;

const collapsePanels = [
  'generalDataStopover',
  'generalDataDocking',
  'operations',
  'bollardsAndFenders',
  'docking_manoeuvre',
  'undocking_manoeuvre',
];

export function InvoiceOperationReportDrawer(props: DockingReportDrawerProps) {
  const { isVisible, setIsVisible, selectedStopover } = props;

  const { onChangePagination, queryPage, setQueryPage } = useChangePagination();
  const [isVisibleDetailDrawer, setIsVisibleDetailDrawer] = useState(false);
  const [detailFilter, setDetailFilter] = useState({});

  const { data: stopoverData, isFetching: isFetchingStopoverData } =
    useGetStopoverGroupbyOperatorQuery(detailFilter);

  useEffect(() => {
    if (stopoverData?.id) setIsVisibleDetailDrawer(true);
  }, [stopoverData]);

  const PAGE_SIZE = 10;

  const [stopoverDescription, setStopoverDescription] = useState(
    {} as StopoverTypeDescription
  );

  const [dockingDescription, setDockingDescription] = useState(
    {} as DockingTypeDescription
  );

  const [vesselDescription, setVesselDescription] = useState(
    {} as VesselTypeDescription
  );

  const { onChangeSwitch, onOpenPanel, openCollapsePanels } =
    useCollapsePanels(collapsePanels);

  const [getStopoverById] = useLazyGetStopoverQuery();

  function onCloseReportDrawer() {
    setDetailFilter({});
    setIsVisibleDetailDrawer(false);
  }

  function onClosetDrawer() {
    setIsVisible(false);
  }

  function onSelectItem(stopover: StopoversGroupByOperatorType) {
    if (stopover?.id) {
      console.log(stopover);
      setDetailFilter({
        ...{ detail_filter_operation_id: stopover.operation_id },
        ...(stopover.operator && {
          detail_filter_operator: stopover.operator,
        }),
        ...(stopover.product_group && {
          detail_filter_product_group: stopover.product_group,
        }),
      });
    }
  }

  return (
    <Drawer visible={isVisible} closable={false} width={1100} destroyOnClose>
      <GenericDrawerHeader
        title={() => {
          return <>Operações</>;
        }}
        showBackButton
        onBack={() => onClosetDrawer()}
      />
      <Container>
        <OperationsTable
          dataSource={selectedStopover?.results || []}
          onChangePagination={onChangePagination}
          total={selectedStopover?.length}
          isLoading={false}
          queryPage={queryPage}
          showSizeChanger={false}
          itemsPerPage={PAGE_SIZE}
          pageSize={PAGE_SIZE}
          onSelectItem={onSelectItem}
        />
      </Container>

      <OperationReportDrawer
        isVisible={isVisibleDetailDrawer}
        onClose={onCloseReportDrawer}
        stopover={stopoverData || ({} as StopoverGroupByOperatorType)}
      />
    </Drawer>
  );
}
